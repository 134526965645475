<template>
  <div class="ez-loader" v-if="$hasLoading(type)" :style="style">
    <Spinner :color="spinnerColor" :size="spinnerSize"/>
  </div>
</template>

<script>
export default {
  name: 'EZLoader',
  props: ['type', 'bg', 'color', 'size'],
  data() {
    return {}
  },
  computed: {
    style() {
      return {
        '--bg': this.bg || '#fff'
      }
    },
    spinnerColor() {
      return this.color || '#221ecd';
    },
    spinnerSize() {
      return this.size || '30px';
    }
  },
}
</script>

<style scoped lang="scss">
$background: var(--bg);

.ez-loader {
  background: $background;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}
</style>